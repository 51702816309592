import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/common/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { desktop, laptop, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageHead from '@/components/app/PageHead'
import Content from '@/components/common/Content'
import Breadcrumb from '@/components/app/Breadcrumb.container'
import ConversionLead from '@/components/modules/Conversion/Lead'

const Page = props => {
  const { site, markdownRemark } = props.data
  const siteMeta = site.siteMetadata
  const { frontmatter, html, excerpt, fields } = markdownRemark
  const post = {
    ...fields,
    ...frontmatter,
    html,
    excerpt
  }
  return (
    <Root>
      <SEO title={post.title} description={post.description || post.excerpt} location={props.location} />
      <LD json={{
        '@type': 'WebPage',
        '@id': `${siteMeta.siteUrl}${post.slug}#webpage`,
        name: `${post.title} | ${siteMeta.title}`,
        description: post.description,
        url: `${siteMeta.siteUrl}${post.slug}`,
        inLanguage: 'ja',
        datePublished: post.date,
        dateModified: post.date,
        isPartOf: LDEntity.WebSite(siteMeta)
      }}/>
      <Breadcrumb title={post.title} path={post.slug}/>
      <Head>
        <PageHead
          title={post.title}
        />
      </Head>
      <Main>
        <Row css={styles.Content}><Content html={post.html}/></Row>
        <Row css={styles.Conversion}><ConversionLead /></Row>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Content: css`
    margin: 0 auto;
    @media ${mq.and(desktop, laptop)} {
      padding-left: 40px;
      padding-right: 40px;
    }
  `,
  Conversion: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(Page)

export const PageTemplateQuery = graphql`
  query PageTemplateQuery($path: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      excerpt(truncate: true)
      fields {
        slug
      }
      frontmatter {
        postType
        description
        date
        title
        tags
      }
    }
  }
`
